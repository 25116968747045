(function($) {
  var preloader = function(_elm) {
    var self = this;
    self.elm = _elm;
    self.classes = [".wrap", "footer", "#main-nav"];

    if (typeof self.elm === "undefined") {
      return false;
    }

    self.init = function() {
      $.each(self.classes, function(index, value) {
        $(value).hide();
      });

      $(window).on("load", self.removePreloader);
    };

    self.removePreloader = function(event) {
      $("body")
        .addClass("loaded")
        .delay(1500, function() {
          $.each(self.classes, function(index, value) {
            $(value).show();
          });

          //Install wow.js
          // var wow = new WOW({
          //   boxClass: "wow", // default
          //   animateClass: "animated", // default
          //   offset: 0, // default
          //   mobile: false, // default
          //   live: true // default
          // });

          // wow.init();

          // $("#loader-wrapper").fadeOut( 450, function(){
          self.elm.remove();
          // });
          $(window).trigger("preloader_removed");
        });
    };

    self.init();

    return self;
  };

  // var p = new preloader($("#loader-wrapper"));
})(jQuery);
