
(function($) {
    
    var mapper = function(){

        var self = this;
        
        self.map_options = {
            scrollwheel: false,
            lat: 0,
            lng: 16.538258,
            title: "Title",
            text: "Text",
            zoom: 15,
            map: null,
            latlng: null,
            mapType: google.maps.MapTypeId.ROADMAP,
            center: 0,
            markers: [
                {
                    lat: 59.611030,
                    lng: 16.538258
                }
            ],
            google_map_style: [{"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]}, {"featureType": "landscape", "elementType": "geometry", "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]}, {"featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{"color": "#ffffff"}, {"lightness": 17}]}, {"featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]}, {"featureType": "road.arterial", "elementType": "geometry", "stylers": [{"color": "#ffffff"}, {"lightness": 18}]}, {"featureType": "road.local", "elementType": "geometry", "stylers": [{"color": "#ffffff"}, {"lightness": 16}]}, {"featureType": "poi", "elementType": "geometry", "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]}, {"featureType": "poi.park", "elementType": "geometry", "stylers": [{"color": "#dedede"}, {"lightness": 21}]}, {"elementType": "labels.text.stroke", "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]}, {"elementType": "labels.text.fill", "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]}, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {"featureType": "transit", "elementType": "geometry", "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]}, {"featureType": "administrative", "elementType": "geometry.fill", "stylers": [{"color": "#fefefe"}, {"lightness": 20}]}, {"featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]}]
        };

        self.init = function(){
            var maps = $('.js-google-map');


            if(maps.length < 1) return;


            var bounds = new google.maps.LatLngBounds();
            var _markers = restaurant_map;
            var allMarkers = [];

            for (var i = 0; i < _markers.length; i++) {


                
                var marker = {
                    lat: parseFloat(_markers[i].lat),
                    lng: parseFloat(_markers[i].lng),
                };
                allMarkers.push(marker);

                var point = new google.maps.LatLng(marker);
                bounds.extend(point);
            }

            // console.log(bounds.getCenter().lat());
            

            
            if (maps.length) {
                // console.log(self.map_options);
                maps.each(function(index, elm){
                    var $map = $(elm);

                
                    self.map_options.lat = bounds.getCenter().lat();
                    self.map_options.lng = bounds.getCenter().lng();

                    self.map_options.zoom = map_zoom;

                    self.map_options.markers = allMarkers;

                    $map.gmap(self.map_options);
                
                });
                
            }
        };


        self.init();
    };
    

    $(document).ready(function(){
        var maps = new mapper();
    });
})(jQuery); // Fully reference jQuery after this point.
