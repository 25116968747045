// "use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ========== ============================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");

      console.log("██████╗ ███████╗\n██╔════╝ ██╔════╝\n██║  ███╗█████╗  \n██║   ██║██╔══╝  \n╚██████╔╝██║     \n╚═════╝ ╚═╝");
      console.log('Produktion Grafikfabriken 2019');
      console.log('https://grafikfabriken.nu/');

      $(window).on("load", function() {
        $(document).ready(function() {
          $('input[rel="txtTooltip"],textarea[rel="txtTooltip"]').tooltip();
        });

        //Install custom select
        $('select:not([name="rating"])').customSelectMenu();

        $('.woocommerce-ordering').off('change', 'select.orderby');

        $('select.orderby').on('change', function () {

          submit($(this).closest('form'));

        });

        function submit($form) {
          setTimeout(function () {
            $form.submit();
          }, 0);
        }


        var $form = $("body").find('form.variations_form');

        $form.on('woocommerce_variation_has_changed', function(){
            var form = $(this);

          form.find('div.custom-select-menu').remove();
          form.find('select').customSelectMenu();

        });

      });
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

var navToggle = document.querySelector(".main-nav__toggle");
var nav = document.querySelector(".main-nav");
var cartToggle = document.querySelector(".main-nav__cart");
var miniCart = document.querySelector(".main-nav__mini-cart");
var miniCartCloseBtn = document.querySelector(
  ".main-nav__mini-cart__close-btn"
);

navToggle.addEventListener("click", function() {
  nav.classList.toggle("navopen");
  navToggle.classList.toggle("navopen");
  miniCart.classList.remove("mini-cart-open");
});

cartToggle.addEventListener("click", function() {
  miniCart.classList.toggle("mini-cart-open");
  nav.classList.remove("navopen");
  navToggle.classList.remove("navopen");
});
miniCartCloseBtn.addEventListener("click", function() {
  miniCart.classList.toggle("mini-cart-open");
});

(function($) {
  $(document).ready(function() {
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      if (scroll > 30) {
        $(".home .main-nav").addClass("scrollTop");
      } else {
        $(".home .main-nav").removeClass("scrollTop");
      }
    });

    $(window).trigger('scroll');
  });
})(jQuery);

(function($) {
  $(document).ready(function() {
    $(".collapse")
      .on("show.bs.collapse", function() {
        $(this)
          .parent()
          .find(".faq__icon")
          .removeClass("fa-plus-circle")
          .addClass("fa-minus-circle");
      })
      .on("hide.bs.collapse", function() {
        $(this)
          .parent()
          .find(".faq__icon")
          .removeClass("fa-minus-circle")
          .addClass("fa-plus-circle");
      });
  });
})(jQuery);

(function($) {
  $(document).ready(function() {
    var input = $("input, textarea");

    input.each(function() {
      if ($(this).val() != "") {
        $(this)
          .closest(".input-parent")
          .addClass("input-active");
      } else {
        $(this)
          .closest(".input-parent")
          .removeClass("input-active");
      }
    });

    input.focus(function() {
      $(this)
        .closest(".input-parent")
        .addClass("input-active");
    });

    input.blur(function() {
      if ($(this).val() != "") {
        $(this)
          .closest(".input-parent")
          .addClass("input-active");
      } else {
        $(this)
          .closest(".input-parent")
          .removeClass("input-active");
      }
    });
  });

  
  
  // Thanks internet
  
  
  $(window).on('load', function () {
    var span = document.createElement('span');
    
    span.className = 'fa';
    span.style.display = 'none';
    document.body.insertBefore(span, document.body.firstChild);
    
    // $('a.ui-datepicker-prev, a.ui-datepicker-next').find('span').css({
    //   fontSize: '12px',
    // });

    
    if ((css(span, 'font-family')) !== 'FontAwesome') {
    
      $('head').after('<style>'+
      'a.ui-datepicker-prev, a.ui-datepicker-next{ font-size:12px!important; width:auto!important; }'+
      '.hasDatepicker .ui-datepicker-next:after, .hasDatepicker .ui-datepicker-prev:after{'+
        'display:none; content:"";'+
      '}'+
      '</style>');
    
    }
    document.body.removeChild(span);
  });

})(jQuery);

function css(element, property) {
  return window.getComputedStyle(element, null).getPropertyValue(property);
}
