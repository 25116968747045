jQuery(function($) {
    // Quantity buttons
    function add_minus_plus() {
        $("div.quantity:not(.buttons_added), td.quantity:not(.buttons_added)")
            .addClass("buttons_added")
            .prepend(
                '<div class="plus increase"><i class="fa fa-plus" aria-hidden="true"></i></div>'
            )
            .append(
                '<div class="minus increase"><i class="fa fa-minus" aria-hidden="true"></i></div>'
            );
    }

    $(document.body).on("updated_wc_div", add_minus_plus);

    add_minus_plus();

    $(document).on("click", ".plus, .minus", function() {
        // Get values
        var $qty = $(this)
                .parent(".quantity")
                .find(".qty"),
            currentVal = parseFloat($qty.val()),
            max = parseFloat($qty.attr("max")),
            min = parseFloat($qty.attr("min")),
            step = $qty.attr("step");

        // Format values
        if (!currentVal || currentVal === "" || currentVal === "NaN")
            currentVal = 0;
        if (max === "" || max === "NaN") max = "";
        if (min === "" || min === "NaN") min = 0;
        if (
            step === "any" ||
            step === "" ||
            step === undefined ||
            parseFloat(step) === "NaN"
        )
            step = 1;

        // Change the value
        if ($(this).is(".plus")) {
            if (max && (max == currentVal || currentVal > max)) {
                $qty.val(max);
            } else {
                $qty.val(currentVal + parseFloat(step));
            }
        } else {
            if (min && (min == currentVal || currentVal < min)) {
                $qty.val(min);
            } else if (currentVal > 0) {
                $qty.val(currentVal - parseFloat(step));
            }
        }

        // Trigger change event
        $qty.trigger("change");
    });
});

jQuery(document).ready(function($) {
    $("body").on("change", "td.product-quantity input[type=number]", function(
        event
    ) {
        var inp = $(this);
        var v = parseInt(inp.attr("value"));
        var m = parseInt(inp.attr("max"));
        if (v > m) {
            alert("Du kan inte ange mer än: " + m);
            inp.attr("value", m);
            //event.stopPropagation();
            //event.preventDefault();
        }
    });
});
