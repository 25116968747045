(function($) {
  var WC_GF = function() {
    $("#toggle-cart").on("click", function(e) {
      e.preventDefault();
      $(".mini-cart").toggleClass("hidden");
    });
    $("#billing_company_field").hide();
    $("a#checkout-company").addClass("fade-out");

    $("body").on("click", "a#checkout-person", function() {
      $("a#checkout-company").toggleClass("fade-out");
      $("a#checkout-person").toggleClass("fade-out");
      $("#billing_company_field").hide().find('input').val('');
    });
    $("body").on("click", "a#checkout-company", function() {
      $("a#checkout-company").toggleClass("fade-out");
      $("a#checkout-person").toggleClass("fade-out");

      $("#billing_company_field").show();
    });

    $(".available_variations").on("change", function() {
      var productId = $(this)
        .find(":selected")
        .data("product-id");
      var variationId = this.value;
      $(".js-add-to-cart-" + productId).attr("data-variation-id", variationId);
      $(".js-add-to-cart-" + productId).attr("data-product-id", productId);
      $(".js-add-to-cart-" + productId).prop("disabled", false);

      $.ajax({
        url: theme.ajax,
        type: "post",
        data: {
          action: "gf_get_variation_image_url",
          pid: productId,
          vid: variationId
        },
        success: function(data) {
          $(".js-product-image-" + productId).attr("src", data.data.image_url);
        }
      });
    });

    $(".add-to-cart").on("click", function(e) {
      var variationId = $(this).attr("data-variation-id");
      var productId = $(this).attr("data-product-id");
      var buttonValue = $(this).html();
      var qty = 1;
      var addButton = $(this);
      $.ajax({
        url: theme.ajax,
        type: "post",
        data: {
          action: "gf_add_to_cart",
          pid: productId,
          vid: variationId,
          qty: qty
        },
        beforeSend: function() {
          addButton.prop("disabled", true);
          addButton.html("Vänta...");
        },
        success: function(data) {
          
          if(data.data.reload_page){
            location.reload();
            return;
          }

          //$(".add-to-cart-message-" + productId).html(data.data.result[0]);
          addButton.prop("disabled", false);
          addButton.html(data.data.result[0]);
          $(document).trigger("wc_update_cart");
          updateCart();
          //window.setTimeout( addButton.html(buttonValue), 5000 );
          setTimeout(function(){

            addButton.html(buttonValue);
          },5000); 
        }
      });
    });

    $(".js-load-more-button").on("click", function(e) {
      var page = $(this).attr("data-page-number");
      var posts_per_page = $(this).data("posts_per_page");
      var productCategory = $(this).attr("data-product-category");
      var blockLayout = $(this).attr("data-block-layout");
      var loadButton = $(this);

      $.ajax({
        url: theme.ajax,
        type: "post",
        data: {
          action: "gf_get_products",
          page: page,
          posts_per_page: posts_per_page,
          productCategory: productCategory,
          blockLayout: blockLayout
        },
        beforeSend: function() {
          loadButton.prop("disabled", true);
          loadButton.html("Vänta...");
        },
        success: function(response) {
          var data = response.data;

          $.each(data.html, function(index, value) {
            var html = $.parseHTML(value);
            $("#js-products-block-" + productCategory).append(html);
            $(html)
              .find("select")
              .customSelectMenu();
          });

          if (data.next_page > 0) {
            loadButton.prop("disabled", false);
            loadButton.html("Det räcker inte, ge mig mer");
            loadButton.attr("data-page-number", data.next_page);
          } else {
            loadButton.hide();
          }
        },
        error: function(xhr, desc, err) {
          console.log(err);
        }
      });
    });

    $(".js-social-share").on("click", function(e) {
      e.preventDefault();
      window.open(
        $(this).attr("href"),
        "fbShareWindow",
        "height=450, width=550, top=" +
          ($(window).height() / 2 - 275) +
          ", left=" +
          ($(window).width() / 2 - 225) +
          ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
      );
      return false;
    });

    function updateCart() {
      $.ajax({
        url: theme.ajax,
        type: "post",
        data: {
          action: "gf_update_cart"
        },
        success: function(data) {
          $("#gf-cart").html(data);
        }
      });

      $.ajax({
        url: theme.ajax,
        type: "post",
        data: {
          action: "gf_update_cart_count"
        },
        success: function(data) {
          $("#gf-cart-count").html(data);
        }
      });
    }
    

    $( document.body ).on( 'updated_wc_div', function(){

      var $link = $('body').find('.js-delivery-reload');

      if($link.length > 0){
        $link.trigger('click');
        window.location = $link.attr('href');
      }
    });
  };

  $(document).ready(function() {
    new WC_GF();
  });
})(jQuery);
