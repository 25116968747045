
(function ($) {

    var restaurant = function () {


        self.init = function () {
            $('#book-filter').on('change', function(){
                
                var $this = $(this);

                
                var translate = gf_restaurant;
                var archive_url = translate.archive_url;
                
                if ($this.val()) {
                    if ($this.val() == 'all') {
                        var full_archive = translate.full_archive;
                        window.location.href = full_archive;
                    } else {
                        window.location.href = archive_url.replace('%%slug%%', $this.val());
                    }
                } 
                
                
            });
        };


        self.init();
    };


    $(document).ready(function () {
        var rest = new restaurant();
    });
})(jQuery); // Fully reference jQuery after this point.
